import { Component, OnInit, Input } from '@angular/core';

import { ISkill } from './skill.interface';

@Component({
  selector: 'app-skill-bar',
  templateUrl: './skill-bar.component.html',
  styleUrls: ['./skill-bar.component.scss'],
})
export class SkillBarComponent implements OnInit {
  @Input() skills: ISkill[];

  constructor() {}

  ngOnInit(): void {}
}
