import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'burger-icon',
  templateUrl: './burger-icon.component.html',
  styleUrls: ['./burger-icon.component.scss'],
})
export class BurgerIconComponent implements OnInit {
  @Input() active: boolean = false;
  @Output() toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  onBurgerClicked() {
    this.active = !this.active;
    this.toggle.emit(this.active);
  }
}
