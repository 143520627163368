<div class="grid-container">
  <app-header class="header"></app-header>

  <app-nav class="nav"> </app-nav>

  <main class="main">
    <router-outlet></router-outlet>
  </main>

  <app-footer class="footer"></app-footer>
</div>
