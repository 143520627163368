<div class="container">
  <div class="label">Skill-Set</div>
  <div *ngFor="let skill of skills" class="skill-set">
    <div class="logo">
      <i
        class="fab"
        [style.color]="skill.color"
        [ngClass]="['fa-' + skill.iconName]"
      ></i>
    </div>
    <div class="title">{{ skill.name }}</div>
  </div>
</div>
