<div class="wrapper">
  <div class="images">
    <img class="background_image" src="assets/stars.jpg" alt="main image" />
    <img class="profile_image" src="assets/riccardo.jpeg" alt="profile image" />
  </div>
  <div class="content">
    <div class="title">Riccardo Lazzari</div>
    <div class="subtitle">Web developer and tester</div>
  </div>
  <div class="footer">
    <a href="https://twitter.com/RiccardoLazzari">
      <span class="fab fab fa-twitter"></span>
    </a>
    <a href="https://www.linkedin.com/in/riccardo-lazzari-02830735/">
      <span class="fab fab fa-linkedin"></span>
    </a>
    <a href="https://github.com/riclazza">
      <span class="fab fab fa-github"></span>
    </a>
  </div>
</div>
