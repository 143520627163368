<div class="brand">
  <div class="title">Riccardo Lazzari</div>
  <div class="subtitle">Web developer / Tester</div>
</div>

<burger-icon
  [active]="isBurgerMenuOpened"
  (toggle)="onToggleBurgerMenu($event)"
></burger-icon>

<app-burger-menu
  [isOpened]="isBurgerMenuOpened"
  (closeMenu)="closeBurgerMenu()"
></app-burger-menu>
