import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projects-carousel',
  templateUrl: './projects-carousel.component.html',
  styleUrls: ['./projects-carousel.component.scss']
})
export class ProjectsCarouselComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
