<div class="container">
  <div class="title">Contact me</div>
  <form class="form" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
    <div class="form-field">
      <input
        class="form-input"
        [ngClass]="{ 'input-error': inputFieldError('name') }"
        formControlName="name"
        type="text"
        placeholder="Full name"
      />
      <label class="label">Full name</label>
      <ng-container *ngIf="name.invalid && (name.touched || name.dirty)">
        <div class="error" *ngIf="name.hasError('required')">
          Please, provide a name
        </div>
      </ng-container>
    </div>

    <div class="form-field">
      <input
        class="form-input"
        [ngClass]="{ 'input-error': inputFieldError('email') }"
        formControlName="email"
        type="email"
        placeholder="Email"
      />
      <label class="label">Email</label>
      <ng-container *ngIf="email.invalid && (email.touched || email.dirty)">
        <div class="error" *ngIf="email.hasError('required')">
          Please, provide an email
        </div>
        <div class="error" *ngIf="email.hasError('email')">
          Please, provide a correct mail
        </div>
      </ng-container>
    </div>

    <div class="form-field">
      <textarea
        class="form-input form-message"
        [ngClass]="{ 'input-error': inputFieldError('message') }"
        formControlName="message"
        placeholder="Message"
      ></textarea>
      <label class="label">Message</label>
      <ng-container
        *ngIf="message.invalid && (message.touched || message.dirty)"
      >
        <div class="error" *ngIf="message.hasError('required')">
          Please, provide a message
        </div>
      </ng-container>
    </div>

    <button class="btn-send" type="submit" [disabled]="!contactForm.invalid">
      Send Message
    </button>
  </form>
</div>
