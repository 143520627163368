<div class="timeline">
  <div class="row1">
    <div class="container left">
      <div class="content">
        <h2>01/20019 | Web Developer</h2>
        <h3>Company: Archimedia srl</h3>
        <p>Frontend web/mobile ( Angular2+ , React , Flutter)</p>
        <p>Backend ( NodeJS , Express , NestJS )</p>
        <p>Automation Testing ( Suitest platform )</p>
      </div>
    </div>
  </div>
  <div class="row2">
    <div class="container right">
      <div class="content">
        <h2>02/20014 | SW Tester</h2>
        <h3>Company: Archimedia srl</h3>
        <p>Broadband/Broadcast applications tester</p>
        <p>Premium Wifi-Cam , Mediaset Infinity , Mediaset Play</p>
        <p>
          Technical support to main electronic consumer companies( Samsung , LG
          , Sony, Panasonic, Hisense)
        </p>
      </div>
    </div>
  </div>
  <div class="row3">
    <div class="container left">
      <div class="content">
        <h2>02/2008 | Software developer</h2>
        <h3>Company: Nonsolomusica snc</h3>
        <p>Project and develop Home Automation Systems</p>
        <p>Crestron - BTcino - Duemmegi - KNX</p>
      </div>
    </div>
  </div>
</div>
