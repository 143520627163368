import {
  Component,
  OnInit,
  Input,
  HostBinding,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-burger-menu',
  templateUrl: './burger-menu.component.html',
  styleUrls: ['./burger-menu.component.scss'],
})
export class BurgerMenuComponent implements OnInit, OnChanges {
  @Input() isOpened: boolean;
  @Output() closeMenu: EventEmitter<any> = new EventEmitter();

  @HostBinding('class.slide-right') openRight = false;
  @HostBinding('class.slide-left') openLeft = false;

  @HostListener('click') onClick() {
    this.closeMenu.emit();
  }

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isOpened.currentValue) {
      this.openRight = true;
      this.openLeft = false;
    } else {
      this.openLeft = true;
      this.openRight = false;
    }
  }
}
