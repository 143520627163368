import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-experience',
  template: ` <app-timeline></app-timeline> `,
  styles: [
    `
      :host {
        text-align: center;
      }
    `,
  ],
})
export class HistoryComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
