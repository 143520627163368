import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'main-card',
  templateUrl: './main-card.component.html',
  styleUrls: ['./main-card.component.scss'],
})
export class MainCardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
