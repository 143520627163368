import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isBurgerMenuOpened: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  onToggleBurgerMenu(event: boolean) {
    this.isBurgerMenuOpened = event;
  }

  closeBurgerMenu() {
    this.isBurgerMenuOpened = false;
  }
}
