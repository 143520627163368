import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavComponent } from './components/nav/nav.component';
import { SkillBarComponent } from './components/skill-bar/skill-bar.component';
import { BurgerMenuComponent } from './components/burger-menu/burger-menu.component';
import { BurgerIconComponent } from './components/burger-icon/burger-icon.component';
import { SkillSetComponent } from './components/skill-set/skill-set.component';
import { CardComponent } from './components/card/card.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NavComponent,
    SkillBarComponent,
    BurgerMenuComponent,
    BurgerIconComponent,
    SkillSetComponent,
    CardComponent,
  ],
  imports: [CommonModule, RouterModule],
  exports: [
    HeaderComponent,
    FooterComponent,
    NavComponent,
    SkillBarComponent,
    BurgerMenuComponent,
    SkillSetComponent,
    CardComponent
  ],
})
export class SharedModule {}
