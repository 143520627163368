<!-- <ul class="menu">
  <li class="menu__element">
    <a href="">
      <i class="fas fa-search"></i>
      <span>About</span>
    </a>
  </li>

  <li class="menu__element">
    <a href="">
      <i class="fas fa-search"></i>
      <span>About</span>
    </a>
  </li>

  <li class="menu__element">
    <a href="">
      <i class="fas fa-search"></i>
      <span>About</span>
    </a>
  </li>
</ul> -->

<div class="menu">
  <a
    [routerLink]="['/about']"
    routerLinkActive="router-link-active"
    class="menu__element"
  >
    <i class="fas fa-male"></i>
    <span>About</span>
  </a>

  <!-- <a
    [routerLink]="['/works']"
    routerLinkActive="router-link-active"
    class="menu__element"
  >
    <i class="fas fa-briefcase"></i>
    <span>Works</span>
  </a> -->

  <a
    [routerLink]="['/history']"
    routerLinkActive="router-link-active"
    class="menu__element"
  >
    <i class="fas fa-history"></i>
    <span>History</span>
  </a>

  <!-- <a
    [routerLink]="['/contact']"
    routerLinkActive="router-link-active"
    class="menu__element"
  >
    <i class="fas fa-at"></i>
    <span>Contact</span>
  </a> -->
</div>
