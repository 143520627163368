import { Component, OnInit } from '@angular/core';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
  contactForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required],
    });
  }

  ngOnInit(): void {}

  get name() {
    return this.contactForm.controls.name;
  }

  get email() {
    return this.contactForm.controls.email;
  }

  get message() {
    return this.contactForm.controls.message;
  }

  inputFieldError(inputName: string) {
    return (
      this.contactForm.get(inputName).invalid &&
      (this.contactForm.get(inputName).touched ||
        this.contactForm.get(inputName).dirty)
    );

    //email.invalid && (email.touched || email.dirty)"
  }

  onSubmit() {
    console.log(this.contactForm.value);
    this.contactForm.markAllAsTouched();
  }
}
