import { Component, OnInit } from '@angular/core';

import { ISkill } from 'src/app/shared/components/skill-set/skill.interface';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  // skills: ISkill[] = [
  //   { name: 'HTML', value: 0 },
  //   { name: 'CSS', value: 10 },
  //   { name: 'JavaScript', value: 20 },
  //   { name: 'jQuery', value: 30 },
  //   { name: 'BootStrap', value: 40 },
  //   { name: 'Photoshop', value: 50 },
  //   { name: 'Angular.js', value: 60 },
  //   { name: 'React.js', value: 70 },
  //   { name: 'PHP', value: 80 },
  //   { name: 'Ruby', value: 90 },
  // ];

  skills: ISkill[] = [
    { name: 'HTML', iconName: 'html5', color: 'orangered' },
    { name: 'CSS', iconName: 'css3', color: 'lightblue' },
    { name: 'JavaScript', iconName: 'js', color: 'goldenrod' },
    { name: 'Angular', iconName: 'angular', color: 'red' },
    { name: 'React.js', iconName: 'react', color: 'blue' },
    { name: 'PHP', iconName: 'php', color: 'darkslateblue' },
    { name: 'Node.Js', iconName: 'node', color: 'green' },
    { name: 'Jira', iconName: 'jira', color: 'blue' },
  ];

  tools: ISkill[] = [{ name: 'Jira', iconName: 'jira', color: 'blue' }];

  constructor() {}

  ngOnInit(): void {}
}
