import { Component, OnInit, Input } from '@angular/core';
import { ISkill } from '../skill-set/skill.interface';

@Component({
  selector: 'app-skill-set',
  templateUrl: './skill-set.component.html',
  styleUrls: ['./skill-set.component.scss'],
})
export class SkillSetComponent implements OnInit {
  @Input() skills: ISkill[];

  constructor() {}

  ngOnInit(): void {}
}
