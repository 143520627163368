// Core imports
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// Modules
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';

// Components
import { AppComponent } from './app.component';
import { HomeComponent } from './routes/home/home.component';
import { WorksComponent } from './routes/works/works.component';
import { ContactComponent } from './routes/contact/contact.component';
import { MainCardComponent } from './routes/home/main-card/main-card.component';
import { ProjectsCarouselComponent } from './routes/home/projects-carousel/projects-carousel.component';
import { ContactFormComponent } from './routes/contact/contact-form/contact-form.component';
import { HistoryComponent } from './routes/history/history.component';
import { TimelineComponent } from './routes/history/timeline/timeline.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    WorksComponent,
    ContactComponent,
    MainCardComponent,
    ProjectsCarouselComponent,
    ContactFormComponent,
    HistoryComponent,
    TimelineComponent,
  ],
  imports: [BrowserModule, AppRoutingModule, ReactiveFormsModule, SharedModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
